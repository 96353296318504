@if (!isFormCorrectlySent) {
  <div class="container-qr-contact-form">
    <form [formGroup]="contactForm">
      <div class="title-container">Envianos un mensaje</div>
      <mat-form-field class="qr-form-field" appearance="outline">
        <mat-label>Nombre y apellido</mat-label>
        <input matInput formControlName="name" required name="name" />
        @if (name.invalid) {
          <mat-error>{{ getErrorMessage('fullName') }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="qr-form-field" appearance="outline">
        <mat-label>Teléfono</mat-label>
        <input
          type="tel"
          matInput
          formControlName="phone"
          required
          name="phone" />
        @if (phone.invalid) {
          <mat-error>{{ getErrorMessage('phone') }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="qr-form-field" appearance="outline">
        <mat-label>Email</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          required
          name="email" />
        @if (email.invalid) {
          <mat-error>{{ getErrorMessage('email') }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="qr-form-field" appearance="outline">
        <textarea
          matInput
          formControlName="message"
          required
          rows="9"
          cols="10"
          class="input-message"
          placeholder="Mensaje"
          type="text"></textarea>
        @if (message.invalid) {
          <mat-error>{{ getErrorMessage('message') }}</mat-error>
        }
      </mat-form-field>
      <div class="button-container">
        <qr-button
          [config]="this.configButton"
          (eventClick)="onSubmit()"></qr-button>
      </div>
      <!-- Disclaimer: Terminos y condiciones -->
      <div id="terms-and-conditions">
        <span>
          Al enviar se están aceptando los
          <a href="/terminos-y-condiciones" target="blank"
            >Términos y Condiciones de Uso</a
          >
          y la
          <a href="/politica-de-privacidad" target="blank"
            >Política de Privacidad</a
          >
        </span>
      </div>
    </form>
  </div>
} @else {
  <!-- Formulario: enviado correctamente -->
  <div id="form-correctly-sent">
    <div id="check">
      <div></div>
    </div>
    <p>
      Información enviada correctamente una oficina adherida a la red RE/MAX se
      pondrá en contacto con vos para continuar con el proceso
    </p>
  </div>
}
