<div class="card-contact-detail">
  <div class="card-contact__top">
    <div class="card-contact-top__title">Contacto</div>
    <div class="contact-buttons">
      <div class="contact-buttons__whatsapp">
        <a
          [href]="urlContactWhatsApp"
          target="_blank"
          (mouseup)="countClicksContacts('counterWhatsApp', $event)">
          <qr-button-x [config]="buttonWhatsappSolidOnlyIcon"></qr-button-x>
        </a>
      </div>
      <div class="contact-buttons__phone">
        <a
          [href]="urlContactPhone"
          target="_blank"
          (mouseup)="countClicksContacts('counterPhone', $event)">
          <qr-button-x [config]="buttonPhoneSolidOnlyIcon"></qr-button-x>
        </a>
      </div>
      <div class="contact-buttons__email">
        <a
          [href]="urlContactEmail"
          target="_blank"
          (mouseup)="countClicksContacts('counterEmail', $event)">
          <qr-button-x [config]="buttonEmailSolidOnlyIcon"></qr-button-x>
        </a>
      </div>
    </div>
  </div>
  <a
    class="card-agent"
    [href]="'/' + agentData?.role + '/' + agentData?.slug"
    target="_blank">
    <div class="card-agent__avatar">
      @if (srcPhotoAgent) {
        <div>
          <picture-source
            [config]="agentResizedImages"
            [src]="resizedImageWebp"
            [alt]="'Foto'"
            height="56px"></picture-source>
        </div>
      } @else {
        <div id="agent-photo-not-found-illustration"></div>
      }
    </div>
    <div class="card-agent__content">
      <div class="card-agent__name">
        {{ nameAgent }}
      </div>
      <div class="card-agent__office">
        {{ officeName }}
      </div>
    </div>
  </a>
  @if (utilsService.isVisibleAuctioneersOffice(node)) {
    <div class="card-brokers">
      Corredores responsables:
      @if (data && data.listBroker && data.listBroker.length > 0) {
        @for (
          broker of data.listBroker;
          track broker.license;
          let idx = $index
        ) {
          <span>
            {{ broker.name }} {{ broker.license }}
            @if (data.listBroker.length - 1 !== idx) {
              <span>,</span>
            }
          </span>
        }
      }
    </div>
  }
  @if (!isCustomAssociateUrlVisible) {
    <div class="card-form">
      <qr-contact-form [data]="data?.id"></qr-contact-form>
    </div>
  }
</div>
